import './style.css';

// import normalizeWheel from 'normalize-wheel-es';

import { gsap, random } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// import { Observer } from 'gsap/Observer';
// import { ScrollSmoother } from 'gsap/ScrollSmoother';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
// gsap.registerPlugin(Observer);
ScrollTrigger.config({ ignoreMobileResize: true });

// import Swiper JS
import Swiper from 'swiper';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import 'swiper/css';

import * as THREE from 'three';

import PopupExpire from './PopupExpire'
import PopupReservation from './PopupReservation'

// import data from '../src/data.js';
// import axios from 'axios';

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////
/// GLOBAL VARS AND UTILS
//////////////////////////////////////////////////////////////////



var show_grid = false;
var project;
var canvas_in_view = false;

// toggle show / hide grid
const grid = document.querySelector('.grid');
if (grid && !show_grid) {
    grid.classList.add('hide');
}
function toggleGrid() {
    if (!grid) return;
    if (grid.classList.contains('hide')) {
        grid.classList.remove('hide');
    } else {
        grid.classList.add('hide');
    }
}

document.onkeyup = (e) => {
    if (e.keyCode == 71) {
        toggleGrid();
    }
};

// mouse params for cube tooltip + coverflow cursor
var mouse_params = {
    target_x: window.innerWidth / 2,
    target_y: window.innerHeight / 2,
    current_x: window.innerWidth / 2,
    current_y: window.innerHeight / 2,
    target_x2: window.innerWidth / 2,
    target_y2: window.innerHeight / 2,
    current_x2: window.innerWidth / 2,
    current_y2: window.innerHeight / 2,
    ease_cube: 0.3,
    ease_tooltip: 0.07,
};

//////////////////////////////////////////////////////////////////
//////// GNB
//////////////////////////////////////////////////////////////////

let lastScrollTop = 0;
const menuBar = document.querySelector('.gnb');
const mobileMenu = document.querySelector('.gnb-mobile');
let menu_animating = false;
// amount of pixel scrolled before hiding menu
var menu_hide_threshold = window.innerWidth > 768 ? 30 : 7;

window.addEventListener('scroll', () => {
    // console.log('scrolling');
    if (!document.body.classList.contains('can-transition')) return;

    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    // console.log(scrollTop - lastScrollTop);

    // prevent menu from hiding override when animating
    if (!menu_animating) {
        if (scrollTop > lastScrollTop) {
            // Downscroll
            // 10px buffer to prevent menu from hiding / showing when user bounce scrolls at the top
            if (scrollTop > 10) {
                if (scrollTop - lastScrollTop > menu_hide_threshold) {
                    menuBar.classList.add('hidden');
                    mobileMenu.classList.add('hidden');
                    document.body.classList.remove('gnb-active');
                    menu_animating = true;

                    // allow menu to show again after 400ms
                    setTimeout(() => {
                        menu_animating = false;
                    }, 400);
                }
            }
        } else {
            // Upscroll
            // 10px buffer to prevent menu from hiding / showing when user bounce scrolls at the top
            if (scrollTop > 10) {
                if (scrollTop - lastScrollTop < menu_hide_threshold * -1) {
                    // console.log('show');
                    menuBar.classList.remove('hidden');
                    mobileMenu.classList.remove('hidden');
                    document.body.classList.add('gnb-active');
                    menu_animating = true;

                    // allow menu to show again after 400ms
                    setTimeout(() => {
                        menu_animating = false;
                    }, 400);
                }
            }
        }
    }
    lastScrollTop = scrollTop;
});

//////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////
//////// ACCORDION
//////////////////////////////////////////////////////////////////

gsap.utils.toArray('.single-accordion').forEach((accordion) => {
    accordion.addEventListener('click', () => {
        // toggle data-open attribute
        accordion.dataset.open = accordion.dataset.open === 'true' ? 'false' : 'true';

        gsap.to(accordion.querySelector('.accordion-content'), {
            height: accordion.dataset.open === 'true' ? 'auto' : 0,
            duration: 0.5,
            ease: 'power2.inOut',
            onComplete: () => {
                if (window.innerWidth > 768) {
                    ScrollTrigger.refresh();
                }
            },
        });
    });
});

//////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////
//////// HERO HEADERS
//////////////////////////////////////////////////////////////////

if (document.querySelector('.section-hero-template')) {
    ///////////////  section-hero-template INTRO
    // used in about, exhibition, visit, amenities

    // buffer 500ms to allow for page scroll reset
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 500);

    let tl_hero_intro = gsap.timeline({ paused: true, delay: 0.6 });
    // 1. transition in title text
    tl_hero_intro.fromTo(
        '.section-hero-template .hero-content .line-div span',
        {
            xPercent: -50,
            yPercent: window.innerWidth > 570 ? 120 : 130,
        },
        {
            xPercent: -50,
            yPercent: 0,

            duration: window.innerWidth > 768 ? 1 : document.body.classList.contains('page-about') ? 1.2 : 1.0,
            stagger: 0.15,
            ease: 'power3.out',
            onStart: () => {
                // about only
                if (document.querySelector('.page-about')) {
                    gsap.to(document.body, {
                        backgroundColor: '#000',
                        onComplete: () => {
                            document.body.setAttribute('data-bg', 'black');
                        },
                    });
                }
            },
        }
    );
    // 2. unmask video
    tl_hero_intro.to(
        '.section-hero-template .hero-media',
        {
            width: '100%',
            height: '100%',
            duration: 1.6,
            ease: 'power3.inOut',
            onComplete: () => {
                heroIntroComplete();
            },
        },
        '-=0.6'
    );

    // use setTimeout instead of onComplete to reveal menu and mobile menu slightly earlier
    setTimeout(() => {
        menuBar.classList.remove('hidden');
        mobileMenu.classList.remove('hidden');
        document.body.classList.add('can-transition');

        setTimeout(() => {
            menuBar.classList.remove('for-intro-only');
            mobileMenu.querySelector('.mobile-menu-header').classList.remove('for-intro-only');
        }, 700);
    }, 2000);

    function heroIntroComplete() {
        // 5. remove body overflow hidden
        document.body.classList.remove('scroll-lock');
        // console.log('hero intro complete');

        initTriggers_BG();
        initTriggers_Title();

        if (document.querySelector('.page-about')) {
            initTriggers_About();
            initImageParallax();
        }
        initTriggers_Stickymenu();

        initTriggers_Footer();
    }

    tl_hero_intro.play();

    ///////////////
}

if (document.querySelector('.section-hero-template-2')) {
    ///////////////  section-hero-template INTRO
    // used in 전시 상세

    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 500);

    let tl_hero_intro2 = gsap.timeline({ paused: true, delay: 0.7 });
    // 1. transition in title text
    tl_hero_intro2.fromTo(
        '.section-hero-template-2 .hero-content .line-div span',
        {
            yPercent: 115,
        },
        {
            yPercent: 0,

            duration: 1,
            stagger: 0.15,
            ease: 'power3.out',
            onStart: () => {},
        }
    );
    // 2. fade in subtitle
    tl_hero_intro2.to(
        '.section-hero-template-2 .section-subtitle',
        {
            opacity: 1,
            duration: 0.6,
            ease: 'power1.inOut',
        },
        '-=0.4'
    );
    // 3. unmask video
    tl_hero_intro2.to(
        '.section-hero-template-2 .hero-media',
        {
            width: '100%',
            // height: '100%',
            duration: 1.6,
            ease: 'power3.inOut',
            onComplete: () => {
                heroIntro2Complete();
            },
        },
        '-=1'
    );

    // use setTimeout instead of onComplete to reveal menu and mobile menu slightly earlier
    setTimeout(() => {
        menuBar.classList.remove('hidden');
        mobileMenu.classList.remove('hidden');
        document.body.classList.add('can-transition');

        setTimeout(() => {
            menuBar.classList.remove('for-intro-only');
            mobileMenu.querySelector('.mobile-menu-header').classList.remove('for-intro-only');
        }, 700);
    }, 2200);

    function heroIntro2Complete() {
        // 5. remove body overflow hidden
        document.body.classList.remove('scroll-lock');

        initTriggers_BG();
        initTriggers_Title();
        initTriggers_Stickymenu();
        initTriggers_Footer();
    }

    tl_hero_intro2.play();
}

//////////////////////////////////////////////////////////////////

function initTriggers_Index() {
    //////// INDEX HERO PIN
    ScrollTrigger.create({
        trigger: '.section-index-hero',
        start: 'top top',
        end: 'bottom top',
        pin: true,
        pinSpacing: false,
        // markers: true,
        onToggle: (self) => {
            if (self.isActive) {
                document.body.classList.add('index-hero-active');
            } else {
                document.body.classList.remove('index-hero-active');
            }
        },
        onLeave: () => {
            // pause hero video on leave
            if (document.querySelector('.section-index-hero .hero-media video')) {
                gsap.set(document.querySelector('.section-index-hero .hero-media video'), { opacity: 0 });
                document.querySelector('.section-index-hero .hero-media video').pause();
            }
        },
        onEnterBack: () => {
            if (document.querySelector('.section-index-hero .hero-media video')) {
                gsap.set(document.querySelector('.section-index-hero .hero-media video'), { opacity: 1 });
                document.querySelector('.section-index-hero .hero-media video').play();
            }
        },
    });
    ///////////////////////////////

    //////// INDEX LIGHTROOM BG GRID
    let tl_index_lightroom = gsap.timeline({
        // yes, we can add it to an entire timeline!
        scrollTrigger: {
            trigger: '.section-index-lightroom .bg-grid',
            pin: true, // pin the trigger element while active
            pinSpacing: false,
            anticipatePin: window.innerWidth < 768 ? 1 : 0,
            start: 'top top', // when the top of the trigger hits the top of the viewport
            endTrigger: '.section-index-lightroom .end-marker',
            end: 'bottom bottom',
            scrub: 0.5, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            // markers: true,
        },
    });

    // add animations and labels to the timeline
    tl_index_lightroom.addLabel('start');
    tl_index_lightroom.to('.bg-grid .inner', { scale: 4, duration: 0.8, ease: 'power1.inOut' }, 'start');
    tl_index_lightroom.to('.bg-grid .inner', { opacity: 0.6, duration: 0.6, ease: 'power1.inOut' }, '-=0.2');
    tl_index_lightroom.fromTo('.bg-grid .gradient-overlay', { opacity: 0 }, { opacity: 1, duration: 0.8, ease: 'power1.inOut' }, 'start');
    tl_index_lightroom.to('.bg-grid .inner', { duration: 0.5 });

    ///////////////////////////////

    //////// INDEX SPACE
    initTriggers_SpaceGraphic();

    ///////////////////////////////

    //////// INDEX - VISIT

    //// PIN VISIT CARDS
    let tl_index_visit = gsap.timeline({
        // yes, we can add it to an entire timeline!
        scrollTrigger: {
            trigger: '.section-index-visit .visit-cards-wrap',
            pin: true, // pin the trigger element while active
            pinSpacing: true,
            anticipatePin: window.innerWidth < 768 ? 1 : 0,
            start: () => '50% 50%', // when the top of the trigger hits the top of the viewport
            end: () => '+=' + window.innerHeight * 1,
            scrub: 0.5, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            // markers: true,
            onToggle: (self) => {
                if (self.isActive) {
                    document.body.setAttribute('data-bg', 'warmgrey');
                }
            },
        },
    });
    // add animations and labels to the timeline

    for (let i = 0; i < document.querySelectorAll('.visit-card.to-anim-card').length; i++) {
        const visit_card = document.querySelectorAll('.visit-card.to-anim-card')[i];

        // random number between -5 and 5

        tl_index_visit.fromTo(visit_card, { y: window.innerHeight, rotateZ: -18 }, { y: '0%', rotateZ: 6, duration: 0.8, ease: 'power2.out' });
    }

    //////// INDEX - FAQ
    let tl_index_faq = gsap.timeline({ paused: true });
    tl_index_faq.addLabel('start');
    tl_index_faq.fromTo('.section-index-faq .single-accordion .border', { scaleX: 0 }, { scaleX: 1, duration: 0.4, stagger: 0.08, ease: 'power2.inOut' }, 'start');
    tl_index_faq.fromTo(
        '.section-index-faq .single-accordion .accordion-header',
        { opacity: 0 },
        { opacity: 1, duration: 0.4, stagger: 0.1, delay: 0.3, ease: 'power1.inOut' },
        'start'
    );
    tl_index_faq.fromTo('.section-index-faq .btn-text', { opacity: 0 }, { opacity: 1, duration: 0.4, stagger: 0.15, delay: 0.3, ease: 'power1.inOut' }, '-=0.7');

    ScrollTrigger.create({
        trigger: '.section-index-faq',
        start: 'top 90%',
        once: false,
        onEnter: () => {
            tl_index_faq.play();
        },
        // markers: true,
    });
    ScrollTrigger.create({
        trigger: '.section-index-faq',
        start: 'top 99%',
        once: false,

        onLeaveBack: () => {
            // reset timeline
            tl_index_faq.time(0);
            tl_index_faq.pause();
        },
    });

    initSwiper();
}

var index_cube;
var index_current_exhibition_active = false;
var index_current_active_firsttime = true;

function initIndexCube() {
    ScrollTrigger.create({
        trigger: '.section-index-current-exhibition',
        start: 'top 90%',
        end: 'bottom 10%',
        once: false,
        onToggle: (self) => {
            if (self.isActive) {
                // if (index_current_active_firsttime) {
                if (!index_current_exhibition_active) {
                    index_current_exhibition_active = true;
                    // console.log('fire');
                    gsap.fromTo(
                        mouse_params,
                        {
                            target_x: window.innerWidth * 0.25,
                            target_y: window.innerHeight * 0.75,
                            target_x2: window.innerWidth * 0.25,
                            target_y2: window.innerHeight * 0.75,
                        },
                        {
                            target_x: window.innerWidth * 0.75,
                            target_y: window.innerHeight * 0.75,
                            target_x2: window.innerWidth * 0.75,
                            target_y2: window.innerHeight * 0.75,
                            duration: 2,
                            ease: 'power2.inOut',
                            onUpdate: () => {
                                // console.log('update', mouse_params.target_x2);
                            },
                            onComplete: () => {
                                setTimeout(() => {
                                    index_current_active_firsttime = false;
                                }, 1000);
                            },
                        }
                    );
                }
            } else {
                index_current_exhibition_active = false;
            }
        },

        // markers: true,
    });

    document.querySelector('.section-index-current-exhibition .cube-hover-area').addEventListener('mousemove', (e) => {
        if (index_current_exhibition_active && !index_current_active_firsttime && window.innerWidth > 1024) {
            mouse_params.target_x = e.clientX;
            mouse_params.target_y = e.clientY;
            //
            mouse_params.target_x2 = e.clientX;
            mouse_params.target_y2 = e.clientY;

            document.querySelector('.section-index-current-exhibition .cube-tooltip').classList.add('show');
        }
    });
    document.querySelector('.section-index-current-exhibition .cube-hover-area').addEventListener('mouseleave', (e) => {
        document.querySelector('.section-index-current-exhibition .cube-tooltip').classList.remove('show');
    });

    // index_cube = document.querySelector('.section-index-current-exhibition .space-cube');

    // tick();
}

//////////////////////////////////////////////////////////////////

var coverflow_carousel;
var coverflow_carousel_cursor = document.querySelector('.page-about .coverflow-cursor');
var coverflow_carousel_active = false;

function initCoverflow_Cursor() {
    coverflow_carousel = document.querySelector('.page-about .carousel-wrap');

    ScrollTrigger.create({
        trigger: '.page-about .carousel-wrap',
        start: 'top 90%',
        end: 'bottom 10%',
        once: false,
        onToggle: (self) => {
            if (self.isActive) {
                coverflow_carousel_active = true;
            } else {
                coverflow_carousel_active = false;
            }
        },

        // markers: true,
    });

    document.querySelector('.page-about .carousel-wrap').addEventListener('mousemove', (e) => {
        if (coverflow_carousel_active) {
            let bounds = coverflow_carousel.getBoundingClientRect();
            mouse_params.target_x = e.clientX - bounds.left;
            mouse_params.target_y = e.clientY - bounds.top;
            //

            document.querySelector('.page-about .coverflow-cursor').classList.add('show');
        }
    });

    document.querySelector('.page-about .carousel-wrap').addEventListener('mouseleave', (e) => {
        document.querySelector('.page-about .coverflow-cursor').classList.remove('show');
    });

    tick();
}

//////////////////////////////////////////////////////////////////

function initTriggers_Title() {
    gsap.utils.toArray('.title-anim-trigger').forEach((titletrigger) => {
        let tl_title = gsap.timeline({ paused: true });
        tl_title.addLabel('start');
        // console.log(titletrigger);
        // special case for index - hello section - mobile
        if (titletrigger.classList.contains('index-case-1')) {
            /// hello - stack title
            tl_title.fromTo(titletrigger.querySelectorAll('.line-div .to-anim'), { yPercent: 115 }, { yPercent: 0, duration: 0.5, stagger: 0.08, ease: 'power3.out' }, 'start');
            tl_title.fromTo(titletrigger.querySelectorAll('.line-div .to-anim'), { opacity: 0 }, { opacity: 1, duration: 0.3, stagger: 0.08, ease: 'power1.inOut' }, 'start');
        } else if (titletrigger.classList.contains('index-case-2')) {
            /// hello - subtitle
            tl_title.fromTo(titletrigger.querySelectorAll('.line-div .to-anim'), { yPercent: 115 }, { yPercent: 0, duration: 0.7, stagger: 0.08, ease: 'power2.out' }, 'start');
            tl_title.fromTo(titletrigger.querySelectorAll('.line-div .to-anim'), { opacity: 0 }, { opacity: 1, duration: 0.5, stagger: 0.08, ease: 'power1.inOut' }, 'start');
        } else {
            /// alll other titles
            tl_title.fromTo(
                titletrigger.querySelectorAll('.line-div .to-anim'),
                { yPercent: 115 },
                {
                    yPercent: 0,
                    duration: window.innerWidth > 768 ? 1 : 0.6,
                    stagger: window.innerWidth > 768 ? 0.12 : 0.2,
                    ease: window.innerWidth > 768 ? 'power3.out' : 'power2.out',
                },
                'start'
            );
            tl_title.fromTo(
                titletrigger.querySelectorAll('.line-div .to-anim'),
                { opacity: 0 },
                { opacity: 1, duration: window.innerWidth > 768 ? 0.5 : 0.45, stagger: window.innerWidth > 768 ? 0.12 : 0.2, ease: 'power1.inOut' },
                'start'
            );
        }

        // on scroll down past titletrigger - play title timeline
        ScrollTrigger.create({
            trigger: titletrigger,
            start: () => (window.innerWidth > 768 ? 'top 85%' : 'top 99%'),
            refreshPriority: 1,
            onUpdate: (self) => self.update(),
            // fastScrollEnd: true,
            // markers: true,
            onEnter: () => {
                tl_title.play();
            },
        });

        // on scroll back up - reset title timeline
        ScrollTrigger.create({
            trigger: titletrigger,
            start: window.innerWidth > 1024 ? 'top 99%' : 'top 99%+=80px',
            // markers: true,

            onLeaveBack: () => {
                // reset timeline
                tl_title.time(0);
                tl_title.pause();
            },
        });
    });
}

function initTriggers_Stickymenu() {
    // init sticky menu
    //// used in 전시 상세, visit

    gsap.utils.toArray('.sticky-section-trigger').forEach((stickytrigger, index) => {
        ScrollTrigger.create({
            trigger: stickytrigger,
            start: 'top 55%',
            end: 'bottom 55%',
            once: false,
            // markers: true,
            onToggle: (self) => {
                // tl_title.play();
                if (self.isActive) {
                    // console.log('active', index);

                    for (let i = 0; i < document.querySelectorAll('.sticky-sidemenu .single-pager').length; i++) {
                        document.querySelectorAll('.sticky-sidemenu .single-pager')[i].classList.remove('active');
                    }
                    document.querySelectorAll('.sticky-sidemenu .single-pager')[index].classList.add('active');

                    // scroll content to active section
                    gsap.to('.sidemenu-pagers', {
                        duration: 0.4,
                        ease: 'power1.inOut',
                        scrollTo: { x: document.querySelectorAll('.sticky-sidemenu .single-pager')[index], offsetX: 20 },
                    });
                }
            },
        });
    });

    gsap.utils.toArray('.sticky-sidemenu .single-pager').forEach((singlepager, index) => {
        singlepager.addEventListener('click', () => {
            console.log('click', index);
            gsap.to(window, {
                scrollTo: { y: document.querySelectorAll('.sticky-section-trigger')[index] },
                duration: 1.3,
                ease: 'power3.inOut',
            });
        });
    });

    if (window.innerWidth < 1080) {
        gsap.utils.toArray('.sticky-half').forEach((sticky_half, index) => {
            // pin sticky half
            ScrollTrigger.create({
                trigger: sticky_half,
                start: 'top 0%',
                endTrigger: '.end-pin-trigger',
                once: false,
                pin: true,
                pinSpacing: false,
                // markers: true,
                onToggle: (self) => {
                    if (self.isActive) {
                        sticky_half.classList.add('is-pinned');
                    } else {
                        sticky_half.classList.remove('is-pinned');
                    }
                },
            });
        });
    }
}

function initTriggers_SpaceGraphic() {
    //// PIN SPACE GRAPHIC SECTION
    ScrollTrigger.create({
        trigger: document.querySelector('.page-about') ? '.section-space-graphic .to-pin' : '.section-space-graphic',
        start: () => 'top top',
        endTrigger: () => '.section-space-graphic .scroll-section-3',
        end: () => 'bottom 98%',
        pin: true,
        anticipatePin: window.innerWidth < 768 ? 1 : 0,
        pinSpacing: false,

        onToggle: (self) => {
            if (self.isActive) {
                document.body.setAttribute('data-bg', 'black');
            }
        },
        // markers: true,
    });

    /// check if canvas is in view
    ScrollTrigger.create({
        trigger: '.section-space-graphic',
        start: 'top bottom',
        end: 'bottom top',

        // markers: true,
        onToggle: (self) => {
            if (self.isActive) {
                canvas_in_view = true;
            } else {
                canvas_in_view = false;
            }
        },
    });

    //// CHANGE ACTIVE INDEX SPACE SECTION ON SCROLL
    gsap.utils.toArray('.section-space-graphic .scroll-section').forEach((scrollsection, i) => {
        ScrollTrigger.create({
            trigger: scrollsection,
            start: 'top 50%',
            end: 'bottom bottom',
            pin: false,
            // markers: true,
            onEnter: () => {
                // console.log('enter', i);
                if (i != 3) {
                    document.querySelector('.section-space-graphic').setAttribute('data-active', i + 1);
                    project.changeStep(i + 1);
                }
            },
            onLeaveBack: () => {
                // console.log('leaveback', i);
                document.querySelector('.section-space-graphic').setAttribute('data-active', i);
                project.changeStep(i);
            },
        });
    });
}

function initTriggers_Footer() {
    let tl_footer_title = gsap.timeline({ paused: true });
    tl_footer_title.addLabel('start');

    // show footer pill button
    tl_footer_title.addLabel('footertopstart');
    tl_footer_title.fromTo('.section-footer .btn-pill', { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'power1.inOut' }, window.innerWidth > 768 ? '-=0.6' : '-=0.3');

    // show footer white line
    let tl_footer_line = gsap.timeline({ paused: true });
    tl_footer_line.fromTo('.section-footer .footer-bottom .div-line', { scaleX: 0 }, { scaleX: 1, duration: window.innerWidth > 768 ? 1.4 : 1, ease: 'power3.inOut' });

    if (window.innerWidth > 1080 && window.innerHeight > 850) {
        /// show footer when scrolling past last section
        // get footer height and set margin bottom of section before footer
        var footer_height = document.querySelector('.section-footer').offsetHeight;
        document.querySelector('.section-before-footer').style.marginBottom = footer_height + 'px';
    }

    // detect the section before footer
    ScrollTrigger.create({
        trigger: '.section-before-footer',
        start: () => window.innerWidth < 768 ? 'bottom bottom-=10px' : 'bottom bottom-=10px',
        end: () => `+=100px`,
        // markers: true,
        id:'footer',
        once: false,
        refreshPriority: 0,
        onEnter: () => {
            gsap.set('.section-footer', { autoAlpha: 1 })
        },
        onLeaveBack: () => {
            // on scroll back up - reset footer anim timeline
            gsap.set('.section-footer', {  autoAlpha: 0 })

            tl_footer_title.time(0);
            tl_footer_title.pause();

            tl_footer_line.time(0);
            tl_footer_line.pause();
        },
    });

    if (window.innerWidth < 768) {
        ScrollTrigger.create({
            trigger: '.section-before-footer',
            start: 'bottom-=120px 99%',
            refreshPriority: 0,
            once: false,

            onEnter: () => {
                gsap.set('.section-footer', { visibility: 'visible', opacity: 1 });
            },
            onLeaveBack: () => {},
        });
    }

    ScrollTrigger.refresh()
    

    ScrollTrigger.create({
        trigger: window.innerWidth > 1080 ? (window.innerHeight > 880 ? '.section-before-footer' : '.footer-bottom') : '.footer-bottom',
        start: () => window.innerWidth > 1080 ? (window.innerHeight > 880 ? 'bottom bottom-=350px' : 'top 90%') : 'top 90%',

        once: false,
        // markers: true,
        onEnter: () => {
            tl_footer_line.play();
        },
    });

    ScrollTrigger.create({
        trigger: '.section-before-footer',
        start: () => window.innerWidth > 1080 ? (window.innerHeight > 880 ? 'bottom bottom-=740px' : 'bottom bottom') : 'bottom bottom',

        once: false,
        // markers: true,
        onEnter: () => {
            // console.log('in');
            tl_footer_title.play();
        },
    });

    /// resize
    window.addEventListener('resize', () => {
        if (window.innerWidth > 1080) {
            if (window.innerHeight > 850) {
                // on desktop, add margin to allow for overlap effect on fixed footer (set by css)
                footer_height = document.querySelector('.section-footer').offsetHeight;
                document.querySelector('.section-before-footer').style.marginBottom = footer_height + 'px';
            } else {
                // on mobile remove margin
                document.querySelector('.section-before-footer').style.marginBottom = '0px';
            }
        } else {
            // on mobile remove margin
            document.querySelector('.section-before-footer').style.marginBottom = '0px';
        }
    });

    // ScrollTrigger.refresh();
}

function initTriggers_BG() {
    //// CHANGE BG COLOR ON SCROLL PAST SECTION
    gsap.utils.toArray('.trigger-bg').forEach((bgtrigger, index) => {
        ScrollTrigger.create({
            trigger: bgtrigger,
            start: () => `top 55%`,
            end: () => `bottom 55%`,
            refreshPriority: 0,
            // markers: true,
            id: `${bgtrigger.getAttribute('data-bgcolor')}`,

            onEnter: () => {
                // console.log(bgtrigger, bgtrigger.getAttribute('data-bgcolor'));
                document.body.setAttribute('data-bg', bgtrigger.getAttribute('data-bgcolor'));

                /// hide contents from outgoing sections
                if (document.body.classList.contains('page-index')) {
                    if (bgtrigger.classList.contains('section-index-current-exhibition')) {
                        document.querySelector('.section-index-hello').classList.add('go-black');
                        gsap.to('.section-index-hello .inner', { opacity: 0, duration: 0.6, ease: 'power1.inOut' });
                    } else if (bgtrigger.classList.contains('section-index-about-artist')) {
                        gsap.to('.cube-hover-area', { opacity: 0, duration: 0.6, ease: 'power1.inOut' });
                    } else if (bgtrigger.classList.contains('section-index-visit')) {
                        gsap.to('.section-space-graphic .inner', { opacity: 0, duration: 0.6, ease: 'power1.inOut' });
                        gsap.to('.section-space-graphic .pagination', { opacity: 0, duration: 0.6, ease: 'power1.inOut' });
                    } else if (bgtrigger.classList.contains('section-index-faq')) {
                        // gsap.killTweensOf('.section-index-visit .content-facilities', 'opacity');
                        // gsap.to('.section-index-visit .content-facilities', { opacity: 0, duration: 0.6, ease: 'power1.inOut' });
                    }
                }
            },
            onEnterBack: () => {
                // console.log(bgtrigger);
                document.body.setAttribute('data-bg', bgtrigger.getAttribute('data-bgcolor'));

                /// reshow contents from incoming sections
                if (document.body.classList.contains('page-index')) {
                    if (bgtrigger.classList.contains('section-index-hello')) {
                        document.querySelector('.section-index-hello').classList.remove('go-black');
                        gsap.to('.section-index-hello .inner', { opacity: 1, duration: 0.6, ease: 'power1.inOut' });
                    } else if (bgtrigger.classList.contains('section-index-current-exhibition')) {
                        gsap.to('.cube-hover-area', { opacity: 1, duration: 0.6, ease: 'power1.inOut' });
                    } else if (bgtrigger.classList.contains('section-space-graphic')) {
                        gsap.to('.section-space-graphic .inner', { opacity: 1, duration: 0.6, ease: 'power1.inOut' });
                        gsap.to('.section-space-graphic .pagination', { opacity: 1, duration: 0.6, ease: 'power1.inOut' });
                    } else if (bgtrigger.classList.contains('section-index-visit')) {
                        gsap.to('.section-space-graphic .inner', { opacity: 1, duration: 0.6, ease: 'power1.inOut' });
                        gsap.killTweensOf('.section-index-visit .content-facilities', 'opacity');
                        gsap.to('.section-index-visit .content-facilities', { opacity: 1, duration: 0.6, ease: 'power1.inOut' });
                    }
                }
            },
        });
    });

    //// CHANGE MENU COLOR ON SCROLL PAST SECTION
    // // for enter only
    gsap.utils.toArray('.trigger-menu-color').forEach((menutrigger, index) => {
        ScrollTrigger.create({
            trigger: menutrigger,
            start: () => `top 1%`,
            end: () => `bottom-=50px 99%`,
            refreshPriority: 0,
            // markers: true,
            id: `x_${menutrigger.getAttribute('data-menucolor')}`,

            onEnter: () => {
                // console.log('enter');
                document.body.setAttribute('data-menucolor', menutrigger.getAttribute('data-menucolor'));
            },
        });
    });
    // // for exit only
    gsap.utils.toArray('.trigger-menu-color').forEach((menutrigger, index) => {
        ScrollTrigger.create({
            trigger: menutrigger,
            start: () => `top 99%`,
            end: () => `bottom-=50px 0%`,
            refreshPriority: 0,
            // markers: true,
            id: `y_${menutrigger.getAttribute('data-menucolor')}`,

            onEnterBack: () => {
                // console.log('enter back');
                document.body.setAttribute('data-menucolor', menutrigger.getAttribute('data-menucolor'));
            },
        });
    });

    if (document.querySelector('.page-index')) {
        ScrollTrigger.create({
            trigger: '.section-index-visit',
            start: () => '98% 10%',
            end: () => '98% 10%',
            // markers: true,
            refreshPriority: 0,

            onEnterBack: () => {
                // console.log('onEnterBack');
                document.body.setAttribute('data-bg', 'warmgrey');
            },
        });

        ScrollTrigger.create({
            trigger: '.section-space-graphic',
            start: () => '98% 5%',
            end: () => '100% 0%',
            // markers: true,
            id: 'bb',
            refreshPriority: 1,

            onLeave: () => {
                // console.log('onLeave indx');
                document.body.setAttribute('data-bg', 'warmgrey');
            },
        });
    }
}

function initSwiper() {
    if (document.querySelector('.swiper-instagram')) {
        // index page - instagram swiper
        var swiper_instagram = new Swiper('.swiper-instagram', {
            grabCursor: true,
            spaceBetween: 28,
            slidesOffsetBefore: 28,
            // centeredSlides: true,
            slidesPerView: 'auto',
        });
    }
    if (document.querySelector('.swiper-coverflow')) {
        var swiper_coverflow = new Swiper('.swiper-coverflow', {
            modules: [EffectCoverflow, Pagination],

            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: window.innerWidth > 768 ? false : false,
            slidesPerView: window.innerWidth > 768 ? '3' : '2.8',
            slidesOffsetBefore: window.innerWidth > 768 ? 0 : window.innerWidth * -0.0395,
            // slideToClickedSlide: true,
            loop: true,
            coverflowEffect: {
                rotate: window.innerWidth > 768 ? 50 : 55,
                stretch: window.innerWidth > 768 ? -100 : -30,
                depth: window.innerWidth > 768 ? 0 : 0,
                slideShadows: false,
            },
            pagination: {
                el: '.swiper-pagination',
            },
        });

        console.log(swiper_coverflow);
        document.querySelectorAll('.swiper-coverflow .swiper-slide').forEach((slide) => {
            slide.addEventListener('click', (e) => {
                let centerSlide = document.querySelector('.swiper-slide-active').nextSibling;

                // check if clicked x position is left or right of center slide (including its width), but if falls within the width of the slide, then do nothing
                if (e.clientX < centerSlide.getBoundingClientRect().x) {
                    swiper_coverflow.slidePrev();
                    //     console.log('left');
                } else if (e.clientX > centerSlide.getBoundingClientRect().x + centerSlide.offsetWidth) {
                    swiper_coverflow.slideNext();
                    //     console.log('right');
                }
            });
        });

        if (window.innerWidth < 768) {
            document.querySelector('.carousel-wrap .slide-prev').addEventListener('click', () => {
                swiper_coverflow.slidePrev();
            });
            document.querySelector('.carousel-wrap .slide-next').addEventListener('click', () => {
                swiper_coverflow.slideNext();
            });
        }
    }
}

function initImageParallax() {
    gsap.utils.toArray('.parallax').forEach((parallax) => {
        gsap.to(parallax, {
            yPercent: -20,
            ease: 'none',
            scrollTrigger: {
                trigger: parallax,
                scrub: 1,
                start: 'top bottom',
                end: 'bottom top',
                // markers: true,
            },
        });
    });
}

function initTriggers_About() {
    initSwiper();
    initTriggers_SpaceGraphic();
    initCoverflow_Cursor();
}

//////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////
//////// PAGE - INDEX
//////////////////////////////////////////////////////////////////

if (document.querySelector('.page-index')) {
    ///////////////  PHASE 1 - INDEX INTRO

    // buffer time for page load and reset scroll
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 500);

    let tl_index_intro = gsap.timeline({ paused: true, delay: 1 });
    // 1. transition in title text
    tl_index_intro.fromTo(
        '.section-intro-gap .line-div span',
        {
            yPercent: 115,
        },
        {
            yPercent: 0,

            duration: 1,
            stagger: 0.15,
            ease: 'power3.out',
            onStart: () => {},
        }
    );

    if (window.innerWidth > 820) {
        // 2. on desktop - unmask video
        tl_index_intro.to(
            '.hero-mask',
            {
                scaleX: 0,
                duration: 1.6,
                ease: 'power3.inOut',
                onStart: () => {
                    // 3. scroll to first section
                    gsap.to(window, {
                        scrollTo: '.section-index-hero',
                        duration: 1.6,
                        ease: 'power3.inOut',
                        onComplete: () => {
                            indexIntroComplete();
                        },
                    });
                    gsap.fromTo('.section-index-hero .hero-content', { opacity: 0 }, { opacity: 1, duration: 1, delay: 0.2, ease: 'power1.inOut' });

                    // 4. move intro gap up
                    gsap.to('.section-intro-gap', { yPercent: -10, duration: 1.6, ease: 'power3.inOut' });
                },
            },
            '-=0.8'
        );
    } else {
        // 2. on mobile - resize video
        tl_index_intro.to(
            '.section-index-hero video',
            {
                width: '100%',
                height: '100%',
                duration: 1.6,
                ease: 'power3.inOut',
                onStart: () => {
                    // 3. scroll to first section
                    gsap.to(window, {
                        scrollTo: '.section-index-hero',
                        duration: 1.6,
                        ease: 'power3.inOut',
                        onComplete: () => {
                            indexIntroComplete();
                        },
                    });
                    // fade in hero content
                    gsap.fromTo('.section-index-hero .hero-content', { opacity: 0 }, { opacity: 1, duration: 1, delay: 0.6, ease: 'power1.inOut' });

                    // 4. move intro gap up
                    gsap.to('.section-intro-gap', { yPercent: -10, duration: 1.6, ease: 'power3.inOut' });
                },
            },
            '-=0.6'
        );
    }

    function indexIntroComplete() {
        // 5. remove intro gap
        window.scrollTo(0, 0);
        gsap.set('.section-intro-gap', { display: 'none' });
        window.scrollTo(0, 0);
        // 6. remove body overflow hidden
        document.body.classList.remove('scroll-lock');

        document.body.setAttribute('data-bg', 'warmgrey');

        initTriggers_Index();
        initTriggers_BG();
        initTriggers_Title();

        initTriggers_Footer();

        document.body.classList.add('can-transition');
        menuBar.classList.remove('hidden');
        mobileMenu.classList.remove('hidden');

        // use setTimeout instead of onComplete to reveal menu and mobile menu slightly earlier
        setTimeout(() => {
            menuBar.classList.remove('for-intro-only');
            mobileMenu.querySelector('.mobile-menu-header').classList.remove('for-intro-only');
        }, 700);

        window.scrollTo(0, 0);
        if (document.querySelector('.popup-expired')) {
            // new PopupExpire();

            gsap.utils.toArray('.popup-expired').forEach((popup) => {
                if (popup.classList.contains('popup-sales')) {
                    new PopupExpire(popup, true)
                } else {
                    new PopupExpire(popup)
                }
                
            })
        }


    }

    tl_index_intro.play();

    ///////////////

    initIndexCube();
}

//////////////////////////////////////////////////////////////////
//////// PAGE - AMENITIES
//////////////////////////////////////////////////////////////////

if (document.querySelector('.page-amenities')) {
    // trigger for section-amenities-shop
    ScrollTrigger.create({
        trigger: '.section-amenities-shop',
        start: 'top 100%',
        once: false,
        onToggle: (self) => {
            if (self.isActive) {
                document.body.classList.remove('force-white');
                document.body.classList.remove('force-white');
            } else {
                document.body.classList.add('force-white');
                document.body.classList.add('force-white');
            }
        },
    });
}

//////////////////////////////////////////////////////////////////
//////// PAGE - POLICY
//////////////////////////////////////////////////////////////////

if (document.querySelector('.page-policy')) {
    initTriggers_Footer();
    document.body.classList.add('can-transition');
    menuBar.classList.remove('hidden');
    mobileMenu.classList.remove('hidden');
}

//////////////////////////////////////////////////////////////////
//////// MOBILE MENU - GNB
//////////////////////////////////////////////////////////////////

/**** TOGGLE MOBILE MENU ****/
var tl_mobile_gnb = gsap.timeline({ paused: true });
tl_mobile_gnb.addLabel('start');

tl_mobile_gnb.set('.mobile-menu-links', { opacity: 1 }, 'start');
tl_mobile_gnb.fromTo('.gnb-mobile .to-anim', { yPercent: 80 }, { yPercent: 0, duration: 0.5, stagger: 0.06, ease: 'power2.out' }, 'start');
tl_mobile_gnb.fromTo(
    '.gnb-mobile .to-anim',
    { opacity: 0 },
    {
        opacity: 1,
        duration: 0.5,
        stagger: 0.06,
        ease: 'power1.inOut',
        onComplete: () => {
            document.querySelector('.gnb-mobile').classList.remove('is-animating');
        },
    },
    'start'
);
tl_mobile_gnb.fromTo(
    '.gnb-mobile .mobile-menu-footer',
    { opacity: 0, display: 'none' },
    { display: 'flex', opacity: 1, duration: 0.45, delay: 0.5, ease: 'power1.inOut' },
    'start'
);

document.querySelector('.mobile-menu-trigger').onclick = () => {
    if (document.querySelector('.gnb-mobile').classList.contains('is-animating')) return;

    document.body.classList.toggle('scroll-lock');
    document.querySelector('.gnb-mobile').classList.toggle('open');
    document.querySelector('.gnb-mobile').classList.add('is-animating');

    if (document.querySelector('.gnb-mobile').classList.contains('open')) {
        tl_mobile_gnb.play();
    } else {
        gsap.to('.mobile-menu-links', {
            opacity: 0,
            duration: 0.4,
            ease: 'power1.inOut',
            onComplete: () => {
                tl_mobile_gnb.seek(0);
                tl_mobile_gnb.pause();
                document.querySelector('.gnb-mobile').classList.remove('is-animating');
            },
        });
        gsap.to('.mobile-menu-footer', {
            display: 'none',
            opacity: 0,
            duration: 0.4,
            ease: 'power1.inOut',
        });

        // close all submenus
        gsap.utils.toArray('.gnb-mobile .submenu-toggle').forEach((submenu_toggle) => {
            submenu_toggle.parentNode.classList.remove('open');
            gsap.to(submenu_toggle.parentNode.querySelector('.submenu-contents'), { height: 0, duration: 0.15, ease: 'power1.inOut' });
        });
    }
};

/************/

/**** TOGGLE MOBILE SUBMENU ****/
gsap.utils.toArray('.gnb-mobile .submenu-toggle').forEach((submenu_toggle) => {
    submenu_toggle.addEventListener('click', () => {
        submenu_toggle.parentNode.classList.toggle('open');

        if (submenu_toggle.parentNode.classList.contains('open')) {
            // open submenu
            gsap.killTweensOf(submenu_toggle.parentNode.querySelector('.submenu-contents'), 'opacity');
            gsap.set(submenu_toggle.parentNode.querySelector('.submenu-contents'), { opacity: 1 });
            gsap.to(submenu_toggle.parentNode.querySelector('.submenu-contents'), { height: 'auto', duration: 0.0, ease: 'power1.inOut' });

            gsap.fromTo(
                submenu_toggle.parentNode.querySelectorAll('.submenu-to-anim'),
                { yPercent: 0 },
                { yPercent: 0, duration: 0.4, stagger: 0.07, delay: 0.05, ease: 'power2.out' }
            );
            gsap.fromTo(
                submenu_toggle.parentNode.querySelectorAll('.submenu-to-anim'),
                { opacity: 0 },
                { opacity: 1, duration: 0.4, stagger: 0.07, delay: 0.05, ease: 'power1.inOut' }
            );
        } else {
            // close submenu
            gsap.to(submenu_toggle.parentNode.querySelector('.submenu-contents'), { opacity: 0, duration: 0.3, ease: 'power1.out' });
        }
    });
});
//////////////////////////////////////////////////////////////////

function tick() {
    if (index_current_exhibition_active) {
        // normalize mouse position from -1 to 1
        mouse_params.current_x += (mouse_params.target_x - mouse_params.current_x) * mouse_params.ease_cube;
        mouse_params.current_y += (mouse_params.target_y - mouse_params.current_y) * mouse_params.ease_cube;
        //
        mouse_params.current_x2 += (mouse_params.target_x2 - mouse_params.current_x2) * mouse_params.ease_tooltip;
        mouse_params.current_y2 += (mouse_params.target_y2 - mouse_params.current_y2) * mouse_params.ease_tooltip;
        // mouse_params.target_x = (mouse_params.target_x - window.innerWidth / 2) / (window.innerWidth / 2);

        // normalized mouse position from -1 to 1
        let normalized_x = (mouse_params.current_x / window.innerWidth) * 2 - 1;
        let normalized_y = (mouse_params.current_y / window.innerHeight) * 2 - 1;
        //
        let normalized_x2 = (mouse_params.current_x2 / window.innerWidth) * 2 - 1;
        let normalized_y2 = (mouse_params.current_y2 / window.innerHeight) * 2 - 1;

        // console.log(mouse_params.current_y, normalized_y);
        index_cube.style.transform = `rotateX( ${-25.5 + normalized_y * 15}deg) rotateY(${normalized_x * -11}deg)`;
        document.querySelector('.section-index-current-exhibition .cube-tooltip').style.transform = `translate(-50%, -50%) translate( ${
            (normalized_x2 * window.innerWidth) / 2
        }px, ${(normalized_y2 * window.innerHeight) / 2}px) translate(70px, -70px) rotateZ(  ${normalized_x2 * 70}deg)`;
    }

    if (coverflow_carousel_active) {
        mouse_params.current_x += (mouse_params.target_x - mouse_params.current_x) * mouse_params.ease_tooltip;
        mouse_params.current_y += (mouse_params.target_y - mouse_params.current_y) * mouse_params.ease_tooltip;

        // console.log(mouse_params.current_x, mouse_params.current_y);
        coverflow_carousel_cursor.style.transform = `translate(-50%, -50%) translate( ${mouse_params.current_x}px,  ${mouse_params.current_y}px) translate(25px, -25px) `;
    }

    requestAnimationFrame(tick);
}

// window.onbeforeunload = function (e) {
//     /// prevent scroll reset on clicking email / tel links
//     // return (protectUnload && typeof document.activeElement.protocol !== 'undefined' && /^http(s)?:$/.test(document.activeElement.protocol)) || null; // return null == no confirm
//     return (typeof document.activeElement.protocol !== 'undefined' && /^http(s)?:$/.test(document.activeElement.protocol)) || null; // return null == no confirm

//     gsap.to(window, { duration: 0.1, scrollTo: { y: 0 } });
//     window.scrollTo(0, 0);
// };

//////////////////////////////////////////////////////////////////
//////// THREEJS - SPACE GRAPHIC
//////////////////////////////////////////////////////////////////

if (document.querySelector('.canvas-container')) {
    class Project {
        constructor() {
            this.container = document.querySelector('.canvas-container');

            const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
            renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
            renderer.setClearColor(0xeeeeee, 0);
            document.querySelector('.canvas-container').appendChild(renderer.domElement);
            this.renderer = renderer;

            const scene = new THREE.Scene();
            // scene.background = new THREE.Color(0x000000);
            this.scene = scene;

            const camera = new THREE.OrthographicCamera(
                this.container.offsetWidth / -2,
                this.container.offsetWidth / 2,
                this.container.offsetHeight / 2,
                this.container.offsetHeight / -2,
                1,
                9000
            );

            // console.log(camera);
            camera.position.set(0, 0, 1000);
            this.camera = camera;

            // this.camera.zoom = 2;
            this.camera.zoom = 1.6;
            if (window.innerWidth < 1780 && window.innerWidth > 1440) {
                if (window.innerHeight < 900) {
                    this.camera.zoom = 1.1;
                }
            } else if (window.innerWidth < 1440 && window.innerWidth > 1080) {
                this.camera.zoom = 1.35;
                if (window.innerHeight < 900) {
                    this.camera.zoom = 1;
                }
            } else if (window.innerWidth < 1080 && window.innerWidth > 820) {
                this.camera.zoom = 1;
            } else if (window.innerWidth < 820 && window.innerWidth > 570) {
                this.camera.zoom = 0.9;
            } else if (window.innerWidth < 570 && window.innerWidth > 380) {
                this.camera.zoom = 0.8;
            } else if (window.innerWidth < 380) {
                this.camera.zoom = 0.65;
            }

            this.camera.updateProjectionMatrix();

            // document.addEventListener('keydown', this.onKeyDown.bind(this), false);
            this.init();
        }

        render() {
            requestAnimationFrame(this.render.bind(this));
            if (canvas_in_view) {
                this.update();
                this.renderer.render(this.scene, this.camera);

                // console.log('render');
            }
        }

        resize() {
            this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;
            this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
            this.camera.left = -this.container.offsetWidth / 2;
            this.camera.right = this.container.offsetWidth / 2;
            this.camera.top = this.container.offsetHeight / 2;
            this.camera.bottom = -this.container.offsetHeight / 2;

            this.camera.zoom = 1.6;
            if (window.innerWidth < 1780 && window.innerWidth > 1440) {
                if (window.innerHeight < 900) {
                    this.camera.zoom = 1.1;
                }
            } else if (window.innerWidth < 1440 && window.innerWidth > 1080) {
                this.camera.zoom = 1.35;
                if (window.innerHeight < 900) {
                    this.camera.zoom = 1;
                }
            } else if (window.innerWidth < 1080 && window.innerWidth > 820) {
                this.camera.zoom = 1;
            } else if (window.innerWidth < 820 && window.innerWidth > 570) {
                this.camera.zoom = 0.9;
            } else if (window.innerWidth < 570 && window.innerWidth > 380) {
                this.camera.zoom = 0.8;
            } else if (window.innerWidth < 380) {
                this.camera.zoom = 0.65;
            }

            this.camera.updateProjectionMatrix();
            // console.log(' , window.innerWidth, window.innerHeight, this.camera.zoom);
        }

        setupResize() {
            window.onresize = this.resize.bind(this);
        }

        randomInt(min, max) {
            var val = Math.floor(Math.random() * (max - min + 1)) + min;
            return val;
        }

        toRadian(val) {
            return (val * Math.PI) / 180;
        }

        init() {
            this.changeSpd = 0.8;

            //
            this.time = 0;
            this.menuIdx = 0;
            this.scaleBase = 0;
            this.scaleRatio = 0;
            this.scaleStep = 0;
            this.scaleSpd = 0;
            this.isChange = false;

            //
            this.initObject();
            this.resize();
            this.render();
            this.setupResize();
        }

        initObject() {
            this.group = new THREE.Group();
            this.scene.add(this.group);

            this.totalCount = 512;

            const objectNum = this.totalCount;
            const objectGeometry = new THREE.SphereGeometry(1, 16, 16);
            const objectMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
            var objectMesh = new THREE.InstancedMesh(objectGeometry, objectMaterial, objectNum);
            objectMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
            this.group.add(objectMesh);
            this.objectMesh = objectMesh;

            this.dataObjects = [];
            this.objectTargets = [];
            for (var i = 0; i < this.totalCount; i++) {
                var object = new THREE.Object3D();
                this.dataObjects.push(object);
            }
            this.dataObjectInput_2();
            this.dataObjectInput_1();
            this.dataObjectInput_3();
            this.dataObjectInput_4();

            for (var i = 0; i < this.totalCount; i++) {
                var object = this.dataObjects[i];
                object.position.copy(this.objectTargets[0][i]);
            }
            this.changeObject(0);
        }

        dataObjectInput_1() {
            var wNum = 8;
            var hNum = 8;
            var dNum = 8;
            var space = 30;
            var stx = (wNum * space) / 2;
            var sty = (hNum * space) / 2;
            var stz = (dNum * space) / 2;
            var idx = 0;
            var target = [];
            for (var m = 0; m < dNum; m++) {
                for (var k = 0; k < hNum; k++) {
                    for (var i = 0; i < wNum; i++) {
                        var object = this.dataObjects[idx];
                        var tx = stx - i * space;
                        var ty = sty - k * space;
                        var tz = stz - m * space;
                        target.push(new THREE.Vector3(tx, ty, tz));
                        idx += 1;
                    }
                }
            }
            this.objectTargets.push(target);
        }

        dataObjectInput_2() {
            var idx = 0;
            var target = [];
            for (var i = 0; i < 32; i++) {
                var theta1 = this.toRadian(180 + (360 / 32) * i);
                var cx = Math.cos(theta1) * 110;
                var cy = Math.sin(theta1) * 110;
                var cz = 0;
                for (var k = 0; k < 16; k++) {
                    var object = this.dataObjects[idx];
                    var r = 50;
                    var tx = cx + Math.cos(this.toRadian((360 / 16) * k)) * r;
                    var ty = cy;
                    var tz = cz + Math.sin(this.toRadian((360 / 16) * k)) * r;
                    const rotatedX = cx + (tx - cx) * Math.cos(theta1) - (ty - cy) * Math.sin(theta1);
                    const rotatedY = cy + (tx - cx) * Math.sin(theta1) + (ty - cy) * Math.cos(theta1);
                    const matrix = new THREE.Matrix4();
                    matrix.setPosition(rotatedX, rotatedY, tz);
                    const position = new THREE.Vector3();
                    position.setFromMatrixPosition(matrix);

                    target.push(position);
                    idx += 1;
                }
            }
            this.objectTargets.push(target);
        }

        dataObjectInput_3() {
            var idx = 0;
            var space = 30;
            var hNum = 8;
            var sty = (-hNum * space) / 2;
            var target = [];
            for (var i = 0; i < 8; i++) {
                var ty = sty + i * space;
                for (var k = 0; k < 64; k++) {
                    var object = this.dataObjects[idx];
                    var r = 140;
                    var tx = Math.cos(this.toRadian((360 / 64) * k)) * r;
                    var tz = Math.sin(this.toRadian((360 / 64) * k)) * r;
                    target.push(new THREE.Vector3(tx, ty, tz));
                    idx += 1;
                }
            }
            this.objectTargets.push(target);
        }

        dataObjectInput_4() {
            var circleNum = 16;
            var particleNum = 32;
            var idx = 0;
            var target = [];
            for (var i = 0; i < circleNum; i++) {
                var pRad = this.toRadian((360 / circleNum) * i);
                var rotationMatrix = new THREE.Matrix4().makeRotationY(pRad);
                for (var k = 0; k < particleNum; k++) {
                    var object = this.dataObjects[idx];
                    var x = Math.cos(this.toRadian((360 / particleNum) * k)) * 160;
                    var y = Math.sin(this.toRadian((360 / particleNum) * k)) * 160;
                    var z = 0;
                    var position = new THREE.Vector3(x, y, z);
                    position.applyMatrix4(rotationMatrix);
                    target.push(position);
                    idx += 1;
                }
            }
            this.objectTargets.push(target);
        }

        changeStep(val) {
            if (val == this.menuIdx) return;
            this.isChange = true;
            this.menuIdx = val;
            this.changeObject(this.changeSpd);
        }

        changeObject(spd) {
            for (var i = 0; i < this.dataObjects.length; i++) {
                var object = this.dataObjects[i];
                var tx = this.objectTargets[this.menuIdx][i].x;
                var ty = this.objectTargets[this.menuIdx][i].y;
                var tz = this.objectTargets[this.menuIdx][i].z;
                gsap.to(object.position, { duration: spd, x: tx, y: ty, z: tz, ease: 'power2.inOut' });
            }
            var targetArr = [
                [0, 0, 0, 3.2, 0.6, 1, 2.0],
                [50, -50, 0, 3.5, 0.4, 5, 2.5],
                [6.5, 0, 0, 3.2, 0.6, 5, 3.0],
                [0, 5, 0, 2.6, 0.85, 1, 2.5],
            ];
            var trgRotationX = this.toRadian(targetArr[this.menuIdx][0]);
            var trgRotationY = this.toRadian(targetArr[this.menuIdx][1]);
            var trgRotationZ = this.toRadian(targetArr[this.menuIdx][2]);
            var trgScaleBase = targetArr[this.menuIdx][3];
            var trgScaleRatio = targetArr[this.menuIdx][4];
            var trgScaleStep = targetArr[this.menuIdx][5];
            var trgScaleSpd = targetArr[this.menuIdx][6];
            gsap.to(this.objectMesh.rotation, { duration: spd, x: trgRotationX, y: trgRotationY, z: trgRotationZ, ease: 'power2.inOut' });
            gsap.to(this, { duration: spd, scaleBase: trgScaleBase, scaleRatio: trgScaleRatio, scaleStep: trgScaleStep, scaleSpd: trgScaleSpd, ease: 'power2.inOut' });
        }

        update() {
            // console.log('update : ', this.isChange);
            for (var i = 0; i < this.totalCount; i++) {
                var object = this.dataObjects[i];
                var tsc = this.scaleBase + Math.cos(this.toRadian(this.time * this.scaleSpd + i * this.scaleStep)) * (this.scaleBase * this.scaleRatio);
                object.scale.set(tsc, tsc, tsc);
                object.updateMatrix();
                this.objectMesh.setMatrixAt(i, object.matrix);
            }
            this.objectMesh.instanceMatrix.needsUpdate = true;
            this.time += 1;
        }

        onKeyDown(event) {
            var event = event || window.event;
            var keycode = event.keyCode;
            switch (keycode) {
                case 49: // 1 : test
                    this.changeStep(0);
                    break;
                case 50: // 2 : test
                    this.changeStep(1);
                    break;
                case 51: // 3 : test
                    this.changeStep(2);
                    break;
                case 52: // 4 : test
                    this.changeStep(3);
                    break;
            }
        }
    }

    project = new Project();
}

//////////////////////////////////////////////////////////////////
// on page load, hide black cover (to prevent seeing content flashing during load)
gsap.to('.black-cover', { opacity: 0, display: 'none', duration: 0.3, delay: 0.5, ease: 'power1.inOut' });

//////////////////////////////////////////////////////////////////
//////// KAKAO MAP + COPY ADDRESS
//////////////////////////////////////////////////////////////////

/// copy text to clipboard (copy address)
function copyTextToClipboard() {
    // Get the text field
    var copyText = document.querySelector('.to-copy').innerHTML;

    // console.log(window.isSecureContext, navigator.clipboard);
    if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(copyText);
        // Copy the text inside the text field
        // navigator.clipboard.writeText(copyText.value);
    } else {
        console.log('https secured origin needed for copy clipboard');
    }

    // temporarily dim .icon-copy
    gsap.to('.icon-copy', {
        opacity: 0.2,
        duration: 0.15,
        ease: 'power1.Out',
        onComplete: () => {
            // undim .icon-copy after
            gsap.to('.icon-copy', { opacity: 1, duration: 0.2, delay: 0.3, ease: 'power1.inOut' });
        },
    });
}

if (document.querySelector('.to-copy')) {
    document.querySelector('.icon-copy').addEventListener('click', () => {
        copyTextToClipboard();
    });
}
function kakaoMapEvent() {
    try {
        kakao.maps.load(() => {
            if (document.querySelector('.map-container') && kakao) {
                var mapContainer = document.querySelector('.map-container'), // 지도를 표시할 div
                mapOption = {
                    center: new kakao.maps.LatLng(33.450701, 126.570667), // 지도의 중심좌표
                    level: 3, // 지도의 확대 레벨
                };
            
                // 지도를 생성합니다
                var map = new kakao.maps.Map(mapContainer, mapOption);
            
                // 주소-좌표 변환 객체를 생성합니다
                var geocoder = new kakao.maps.services.Geocoder();
            
                // 주소로 좌표를 검색합니다
                geocoder.addressSearch('강동구 아리수로61길 103', function (result, status) {
                    // 정상적으로 검색이 완료됐으면
                    if (status === kakao.maps.services.Status.OK) {
                        var coords = new kakao.maps.LatLng(result[0].y, result[0].x);
            
                        console.log(result[0].y, result[0].x);
            
                        // 결과값으로 받은 위치를 마커로 표시합니다
                        // var marker = new kakao.maps.Marker({
                        //     map: map,
                        //     position: coords,
                        // });
            
                        // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
                        map.setCenter(coords);
                    } else {
                        document.querySelector('.exhibition-map').classList.add('no-map');
                    }
                });
            
                var imageSrc = '/images/visit/map-icon.png', // 마커이미지의 주소입니다
                    imageSize = new kakao.maps.Size(125 / 2, 148 / 2), // 마커이미지의 크기입니다
                    imageOption = { offset: new kakao.maps.Point(27, 69) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
            
                // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
                var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption),
                    markerPosition = new kakao.maps.LatLng(37.5653931322584, 127.147715238632); // 마커가 표시될 위치입니다
            
                // 마커를 생성합니다
                var marker = new kakao.maps.Marker({
                    position: markerPosition,
                    image: markerImage, // 마커이미지 설정
                });
            
                // 마커가 지도 위에 표시되도록 설정합니다
                marker.setMap(map);
            }
        })
    } catch(error) {}
    
}
if (document.querySelector('.map-container')) {
    kakaoMapEvent()
}



/// prevent browser from restoring previous scroll position on page refresh
if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
}



window.onload = () => {
    if (!document.querySelector('.popup-reservation')) {
        new PopupReservation()
    }
}